import React from 'react';

export default function IsiFrame({onClick}){
    return(
        <div className='isi-frame-sticky'>
            <div className='isi-frame-container container-1344'>
                <div className='isi-frame-sticky-left'>
                    <h5>INBRIJA<sup>®</sup> Indication</h5>
                    <p>Treats OFF periods in adults taking carbidopa/levodopa (CD/LD). INBRIJA doesn't replace CD/LD.</p>
                </div>
                <div className='isi-frame-sticky-left isi-frame-sticky-right'>
                    <h5>Important Safety Information</h5>
                    <p>Don't use if you have taken a nonselective monoamine oxidase inhibitor (eg, phenelzine, tranylcypromine) within the last 2 weeks.</p>
                </div>
                <div className='see-more' onClick={onClick}>SEE MORE <img src='/assets/images/see-more-dropdown.webp' className='dropdown-image' alt='see-more'  /></div>
            </div>
        </div>
    )
}