import React,{useState, useEffect} from "react";
import Header from "../../Header/header";
import HeaderMenu from "../../Header/header-menu";
import Isi from "../../ISI/Isi";
import Footer from "../../Footer/Footer";
import HeroComponent from "../../HeroComponent/HeroComponent";
import './Unsubscribe.css'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function UnSubscribePage(){
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_URL;
    const [anonId, setAnonId] = useState("");
    const validateForm = (e) => {
        var sendForm = 1;
        var emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;;

        var email = document.getElementById('email');
        var option1 = document.getElementById('option1');
        var option2 = document.getElementById('option2');
        var option3 = document.getElementById('option3');

        if(!email.value.match(emailRegex))
        {
            sendForm = 0;
            email.classList.add('error');
        }
        else
        {
            email.classList.remove('error');
        }

        if(!option1.checked && !option2.checked && !option3.checked){
            sendForm = 0;
            option1.classList.add('error');
            option2.classList.add('error');
            option3.classList.add('error');
        }
        else
        {
            option1.classList.remove('error');
            option2.classList.remove('error');
            option3.classList.remove('error');
        }

        if(option1.checked && option2.checked)
        {
            option3.checked = true;
        }
        else if((option1.checked && !option2.checked) || (!option1.checked && option2.checked))
        {
            option3.checked = false;
        }
        else if(option3.checked){
            option1.checked = true;
            option2.checked = true;
        }
        else{
            option1.checked = false;
            option2.checked = false;
            option3.checked = false;
        }
        return sendForm;
    }
    useEffect(() => {
        const getCookieValue = (cookieName) => {
          const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith(cookieName + '='))
            ?.split('=')[1];
    
          return cookieValue;
        };
        const anonID = getCookieValue('Anon_ID');
        setAnonId(anonID)
      }, []);
    const unsubscribeForm = (e) => {
        e.preventDefault();
        var status = validateForm();
        var email = document.getElementById('email');
        if(status==1)
        {
            fetch(`${url}/signup`, {
                method: 'POST',
                body: { 
                    email : email,
                    source_name : "inbrija_pwp_brand_unsub",
                    unsub_code : "inbrija-pwp",
                    // visitor_id : anonId
                    visitor_id: "00000000-0000-0000-0000-000000000000",
              },
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then((data) => {    
                navigate('/unsubscribe-confirmation')
              })  
        
        }
    }
    return(
        <div className="unsubscribe">
             <Helmet>
                <title>Unsubscribe | INBRIJA® (levodopa inhalation powder)</title>
            </Helmet>
            <Header />
            <HeaderMenu>
                <div className="hero-section">
                    <div className="container">
                        <h1>Unsubscribe</h1>
                    </div>
                </div>
            </HeaderMenu>
            <div className="brush-style"></div>

            <form className="unsubscribe-container container" id="form-unsubscribe" onSubmit={unsubscribeForm}>
                <div className="form-group unsubscribe-email-field">
                    <label>Enter your email address and select the information you no longer wish to receive.</label>
                    <input id="email" name="email" type="email" className="form-control" placeholder="Email Address" onChange = {()=> validateForm()}/>
                </div>
                <div className="form-group unsubscribe-options-field">
                    <label>Select the type(s) of communications to unsubscribe from:</label>
                    <div className="radio_boxes">
                        <div className="radio_box">
                            <input type="checkbox" id="option1" onChange={()=> validateForm()}/>
                            <label for="option1">All INBRIJA-related emails from Acorda</label>
                        </div>
                        <div className="radio_box">
                            <input type="checkbox" id="option2" onChange={()=> validateForm()}/>
                            <label for="option2">Other Acorda products and services</label>
                        </div>
                        <div className="radio_box">
                            <input type="checkbox" id="option3" onChange={()=> validateForm()}/>
                            <label for="option3">All Acorda communications</label>
                        </div>
                    </div>
                </div>
                <div className="unsubscribeButton">
                    <button type="submit">Unsubscribe</button>
                </div>
            </form>
            <Isi />
            <Footer 
            code="06/23 INB13297" 
            year="2023"
            twitterCode="https://twitter.com/intent/tweet?text=Learn%20more%20about%20INBRIJA.%20INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more.%20https://www.inbrija.com"
            facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2F&title=Learn%20more%20about%20INBRIJA&description=INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more." />
        </div>
    )
}