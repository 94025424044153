import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Homepage from './views/pages/Homepage/Homepage';
import "bootstrap/dist/css/bootstrap.min.css";
import UnSubscribePage from './views/pages/Unsubscribe/Unsubscribe';
import UnSubscribed from './views/pages/Unsubscribe/Unsubscribed'
import OffPeriods from './views/pages/OffPeriods/OffPeriods';
import CommonQuestion from './views/pages/CommonQuestion/Common-question';
import Error from './views/pages/Error/Error';
import ContactUs from './views/pages/ContactUs/Contact-us';
import SiteMap from './views/pages/SiteMap/Site-map';
import Thankyou from './views/pages/Thankyou/Thankyou';
import HowToUseInbrija from './views/pages/HowToUse/How-to-use-inbrija';
import SavingSupport from './views/pages/SavingSupport/Saving-and-support';
import PeopleOnInbrija from './views/pages/PeopleOnIbrija/PeopleOnIbrija';
import AboutInbrija from './views/pages/AboutInbrija/About-Inbrija';
import { useEffect } from 'react';
import ScrollToTop from './views/Scrolltotop';
import ThankyouEng from './views/pages/Thankyou/Thankyou-eng';
import { HelmetProvider } from 'react-helmet-async';
import PDFResources from './views/Layouts/Pdf-resources';

function App({ props }) {

  const routes = [
    { path: '/', component: Homepage },
    { path: "/about-inbrija", component: AboutInbrija },
    { path: "/contact-us", component: ContactUs },
    { path: "/how-to-use-inbrija", component: HowToUseInbrija },
    { path: "/savings-and-support", component: SavingSupport },
    { path: "/for-people-on-inbrija", component: PeopleOnInbrija },
    { path: "/language-thank-you", component: ThankyouEng },
    { path: "/pdf-resources", component: PDFResources },
    { path: "/common-questions", component: CommonQuestion },
    { path: "/sitemap", component: SiteMap },
    { path: "/thank-you", component: Thankyou },
    { path: "/unsubscribe-confirmation", component: UnSubscribed },
    { path: '/unsubscribe', component: UnSubscribePage },
    { path: "/what-are-off-periods", component: OffPeriods },
  ];

  useEffect(() => {
    const container = document.getElementById('routes-id');
    routes.forEach(route => {
      const link = document.createElement('a');
      link.href = route.path;
      link.innerHTML = "";
      container.appendChild(link);
    });
  }, [routes]);

  const dynamicURL = window.location.href;


  return (
    <div className="App" id='routes-id'>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route path="*" element={<Error />} />
            <Route exact path="/patient-video" element={<Navigate replace to="/" />} />
            <Route exact path="/people-on-inbrija" element={<Navigate replace to="/" />} />
            <Route exact path="/sign-up" element={<Navigate replace to="/" />} />
            <Route exact path="/telemedicine" element={<Navigate replace to="/" />} />
            <Route exact path="/tools-and-resources" element={<Navigate replace to="/" />} />
            <Route exact path="/tools-resources" element={<Navigate replace to="/" />} />
            <Route exact path="/off-periods" element={<Navigate replace to="/what-are-off-periods" />} />
            <Route exact path="/how-to-use" element={<Navigate replace to="/how-to-use-inbrija" />} />
            <Route exact path="/about" element={<Navigate replace to="/about-inbrija" />} />
            <Route exact path="/ask-for-inbrija" element={<Navigate replace to="/about-inbrija" />} />
            <Route exact path="/talking-to-doctor" element={<Navigate replace to="/about-inbrija#talking-with-doctor" />} />
            <Route exact path="/financial-services" element={<Navigate replace to="/savings-and-support#savings-programs" />} />
            <Route exact path="/savings-programs" element={<Navigate replace to="/savings-and-support#savings-programs" />} />
            <Route exact path="/patient-support" element={<Navigate replace to="/savings-and-support#prescription-services" />} />
            <Route exact path="/prescription-support-services" element={<Navigate replace to="/savings-and-support#prescription-services" />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;


