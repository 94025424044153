import React from "react";
import Header from "../../Header/header";
import HeaderMenu from "../../Header/header-menu";
import Isi from "../../ISI/Isi";
import Footer from "../../Footer/Footer";
import HeroComponent from "../../HeroComponent/HeroComponent";
import './Unsubscribe.css'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function UnSubscribePage() {
    const navigate = useNavigate();
    return (
        <div className="unsubscribe">
            <Helmet>
                <title>Unsubscribe Confirmation | INBRIJA® (levodopa inhalation powder)</title>
            </Helmet>
            <Header />
            <HeaderMenu>
                <div className="hero-section">
                    <div className="container">
                        <h1>Unsubscribe</h1>
                    </div>
                </div>
            </HeaderMenu>
            <div className="brush-style"></div>

            <div className="unsubscribe-confirmation-container container">
                <h2>You've unsubscribed</h2>
                <p>You may continue to receive communications for a short period of time while we process your request.</p>
                <a onClick={() => navigate('/')}>Go To Home Page</a>
            </div>
            <Isi />
            <Footer 
            code="06/23 INB13297" 
            year="2023"
            twitterCode="https://twitter.com/intent/tweet?text=Learn%20more%20about%20INBRIJA.%20INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more.%20https://www.inbrija.com"
            facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2F&title=Learn%20more%20about%20INBRIJA&description=INBRIJA%20is%20inhaled%20levodopa.%20Ask%20your%20doctor%20to%20learn%20more." />
        </div>
    )
}